






































































import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { BContainer, BRow, BCol } from "bootstrap-vue";
import MenuFooter from "@/views/layout/footer/MenuFooter.vue";
import ModalLinkProgressChat from "@/components/modal/ModalLinkProgressChat.vue";
@Component({
  components: {
    BRow,
    BContainer,
    BCol,
    MenuFooter,
    ModalLinkProgressChat,
  },
})
export default class Home extends Vue {
  username = "Jhonatan";
  displayTicketKosong = false;
  displayListTicket = true;
  @Ref() modalLink!: ModalLinkProgressChat;

  showModal() {
    // this.modalLink.showModal();
  }
}
