


















import Vue from "vue";
import { Component } from "vue-property-decorator";
@Component({
  components: {},
})
export default class ModalLinkProgressChat extends Vue {
  modalShow = false;
  ticketId: string | null = null;
  showModal(idTicket: string) {
    this.modalShow = true;
    this.ticketId = idTicket;
  }
  progress() {
    this.modalShow = false;
    this.$router.push({ path: "/progress/" + this.ticketId });
  }
  chat() {
    this.modalShow = false;
    this.$router.push({ name: "chat" });
  }
}
